const DynamicName = process.env.VUE_APP_COMPANY_NAME;
const CRA_Train_321= {
    'SITE_NAME': 'California Restaurant Association',
    'FOOTER_NOTICE': 'Train 321, LLC',
    'SITE_URL': 'https://cra.train321.com',
    'LOGO': 'cra_logo.png',
    'INFO_EMAIL': 'cra@train321.com',
    'SUPPORT_EMAIL': 'support@train321.com'
}
 const Home_of_Training = {
    'SITE_NAME': 'Home Of Training',
    'FOOTER_NOTICE': 'Home Of Training, INC',
    'SITE_URL': 'www.homeoftraining.com',
    'LOGO': 'Homeoftraining.png',
    'INFO_EMAIL': 'info@homeoftraining.com',
    'SUPPORT_EMAIL': 'support@homeoftraining.com'
}
let Dynamic = {};
switch (DynamicName) { 
    case "CRA_Train_321":
        Dynamic[DynamicName] = CRA_Train_321;
        break;
    case "Home_of_Training":
        Dynamic[DynamicName] = Home_of_Training;
        break;
    default:
       Dynamic["CRA_Train_321"] = CRA_Train_321;  
}
let DName = Dynamic[DynamicName];
export  { DName as Dynamic }  ;
