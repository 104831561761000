import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "@/views/GeneralViews/NotFoundPage.vue";
import CreateEmailTemplate from "../views/Super/CreateEmailTemplate.vue";
import EmailMarketingTemplate from "../views/Super/EmailMarketingTemplate.vue";
import Marketing from "../views/Super/Marketing.vue";

//  admin
const AllCompanies = () => import("@/views/Super/AllCompanies.vue");
const EditCompany = () => import("@/views/Super/EditCompany.vue");
const Certificates = () => import("@/views/Super/Certificates.vue");
const Badges = () => import("@/views/Super/AllBadges.vue");
const CreateBadge = () => import("@/views/Super/CreateBadge.vue");
const EmployeeBadges = () => import("@/views/Super/EmployeeBadges.vue");
const Account = () => import("@/views/Super/Account.vue");
const TutorialVideo = () => import("@/views/Super/TutorialVideo.vue");
const TourPage = () => import("@/views/Super/TourPage.vue");
const AddTutorialVideo = () => import("@/views/Super/AddTutorialVideo.vue");
const AddTourVideo = () => import("@/views/Super/AddTourVideo.vue");
const CreateCertificate = () => import("@/views/Super/CreateCertificate.vue");
const Courses = () => import("@/views/Super/Courses.vue");
const CreateCourse = () => import("@/views/Super/CreateCourse.vue");
const CourseFolder = () => import("@/views/Super/CourseFolder.vue");
const CourseFolderDetails = () =>
	import("@/views/Super/CourseFolderDetails.vue");
const CreateCourseFolder = () => import("@/views/Super/CreateCourseFolder.vue");
const CertificateDetails = () => import("@/views/Super/CertificateDetails.vue");
const CreateCompany = () => import("@/views/Super/CreateCompany.vue");
const AllUsers = () => import("@/views/Super/AllUsers.vue");
const CompanyLocations = () => import("@/views/Super/CompanyLocations.vue");
const AddLocation = () => import("@/views/Super/AddLocation.vue");
const CompanyCertificates = () =>
	import("@/views/Super/CompanyCertificates.vue");
const CompanyCourses = () => import("@/views/Super/CompanyCourses.vue");
const CompanyCourseFolders = () =>
	import("@/views/Super/CompanyCourseFolders.vue");
const LocationDetails = () => import("@/views/Super/LocationDetails.vue");
const CourseCatalog = () => import("@/views/Super/CourseCatalog.vue");
const CompanyCourseDetails = () =>
	import("@/views/Super/CompanyCourseDetails.vue");
const CourseInstructions = () => import("@/views/Super/CourseInstructions.vue");
const LessonForm = () => import("@/views/Super/LessonForm.vue");
const EditCourse = () => import("@/views/Super/EditCourse.vue");
const EmployeeCertificates = () =>
	import("@/views/Super/EmployeeCertificates.vue");
const EmployeeResources = () => import("@/views/Super/EmployeeResources.vue");
const EmployeeDocuments = () => import("@/views/Super/EmployeeDocuments.vue");
const EmployeeCourses = () => import("@/views/Super/EmployeeCourses.vue");
const AddEmployee = () => import("@/views/Super/AddEmployee.vue");
const CreateUser = () => import("@/views/Super/CreateUser.vue");
const CompanyEmployees = () => import("@/views/Super/CompanyEmployees.vue");
const Resources = () => import("@/views/Super/Resources.vue");
const LoginReport = () => import("@/views/Super/LoginReport.vue");
const ActivityReport = () => import("@/views/Super/ActivityReport.vue");
const CourseFailPassReport = () =>
	import("@/views/Super/CourseFailPassReport.vue");
const UserOnboardingReport = () =>
	import("@/views/Super/UserOnboardingReport.vue");
const AllOnboardingReport = () =>
	import("@/views/Super/AllOnboardingReport.vue");
const HrFormReport = () => import("@/views/Super/HrFormReport.vue");
// Dashboard pages
const Dashboard = () => import("@/views/Dashboard/Dashboard.vue");

// Pages
const Tour = () => import("@/views/Pages/Tour.vue");
const Home = () => import("@/views/Pages/Home.vue");
const Register = () => import("@/views/Pages/Register.vue");
const UserRegister = () => import("@/views/Pages/UserRegister.vue");
const Signup = () => import("@/views/Pages/Signup.vue");
const ForgetPassword = () => import("@/views/Super/ForgetPassword.vue");
const Login = () => import("@/views/Pages/Login.vue");
const LoginInput = () => import("@/views/Pages/LoginInput.vue");
const SexualHarassment = () => import("@/views/Pages/SexualHarassment.vue");

const ResetPassword = () => import("@/views/Super/ResetPassword.vue");
const TestQuestionReport = () => import("@/views/Super/TestQuestionReport.vue");
const SurveyReport = () => import("@/views/Super/SurveyReport.vue");
const SurveySubmissions = () => import("@/views/Super/SurveySubmissions.vue");
const SurveySubmissionsDetail = () =>
	import("@/views/Super/SurveySubmissionsDetail.vue");
const CreateSubAdmin = () => import("@/views/Super/CreateSubAdmin.vue");
const AllSubAdmin = () => import("@/views/Super/AllSubAdmin.vue");

const ScormReport = () => import(/* webpackChunkName: "resources" */ '@/views/Super/ScormReport.vue');
const ScormReportDetails = () => import(/* webpackChunkName: "resources" */ '@/views/Super/ScormReportDetails.vue');

let authPages = {
	path: "/",
	component: AuthLayout,
	name: "Authentication",
	children: [
		{
			path: "/home",
			name: "Home",
			component: Home,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: "/login",
			name: "Login",
			component: Login,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: "/signup",
			name: "Signup",
			component: Signup,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: "/logininput",
			name: "LoginInput",
			component: LoginInput,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: "/sexual_harassment",
			name: "SexualHarassment",
			component: SexualHarassment,
			meta: {
				noBodyBackground: true
			}
		},

		{
			path: "/tour",
			name: "Tour",
			component: Tour,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: "/forget_password",
			name: "ForgetPassword",
			component: ForgetPassword,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: "/reset_password",
			name: "ResetPassword",
			component: ResetPassword,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: "/register",
			name: "Register",
			component: Register,
			meta: {
				noBodyBackground: true
			}
		},
		{
			path: "/user_register",
			name: "User Register",
			component: UserRegister,
			meta: {
				noBodyBackground: true
			}
		},
		// {
		//   path: "/signup",
		//   name: "Sign Up",
		//   component: Signup,
		//   meta: {
		//     noBodyBackground: true
		//   }
		// },

		{path: "*", component: NotFound}
	]
};

const routes = [
	{
		path: "/",
		redirect: "/login",
		name: "Login"
	},

	{
		path: "/",
		component: DashboardLayout,
		redirect: "/dashboard",
		name: "Dashboard",
		children: [
			{
				path: "dashboard",
				name: "Dashboard",
				component: Dashboard,
				meta: {
					title: 'Dashboard',
					page: 'dashboard',
				},
			},
			{
				path: "all_companies",
				name: "All Companies",
				component: AllCompanies
			},
			{
				path: "edit_company",
				name: "Edit Company",
				component: EditCompany
			},
			{
				path: "certificates",
				name: "Certificates",
				component: Certificates
			},
			{
				path: "courses",
				name: "Courses",
				component: Courses
			},
			{
				path: "create_course",
				name: "CreateCourse",
				component: CreateCourse
			},
			{
				path: "course_folder",
				name: "CourseFolder",
				component: CourseFolder
			},
			{
				path: "course_folder_details",
				name: "CourseFolderDetails",
				component: CourseFolderDetails
			},
      {
				path: '/marketing',
				name: 'marketing',
				component: Marketing
			},
			{
				path: '/email_template',
				name: 'emailTemplate',
				component: EmailMarketingTemplate
			},
			{
				path: '/create_email_template',
				name: 'emailTemplate',
				component: CreateEmailTemplate
			},
			{
				path: "create_course_folder",
				name: "CreateCourseFolder",
				component: CreateCourseFolder
			},
			{
				path: "certificate_details",
				name: "CertificateDetails",
				component: CertificateDetails
			},
			{
				path: "create_company",
				name: "CreateCompany",
				component: CreateCompany
			},
			{
				path: "test_question_report",
				name: "TestQuestionReport",
				component: TestQuestionReport
			},
			{
				path: "survey_report",
				name: "SurveyReport",
				component: SurveyReport
			},
			{
				path: "survey_submissions",
				name: "SurveySubmissions",
				component: SurveySubmissions
			},
			{
				path: "survey_submissions_detail",
				name: "SurveySubmissionsDetail",
				component: SurveySubmissionsDetail
			},
			{
				path: "account",
				name: "Account",
				component: Account
			},
			{
				path: "badges",
				name: "Badges",
				component: Badges
			},
			{
				path: "employee_badges",
				name: "EmployeeBadges",
				component: EmployeeBadges
			},

			{
				path: "create_badge",
				name: "CreateBadge",
				component: CreateBadge
			},
			{
				path: "tutorial_video",
				name: "TutorialVideo",
				component: TutorialVideo
			},
			{
				path: "add_tutorial_video",
				name: "AddTutorialVideo",
				component: AddTutorialVideo
			},
			{
				path: "tour_page",
				name: "TourPage",
				component: TourPage
			},
			{
				path: "add_tour_video",
				name: "AddTourVideo",
				component: AddTourVideo
			},
			{
				path: "create_certificate",
				name: "CreateCertificate",
				component: CreateCertificate
			},
			{
				path: "all_users",
				name: "All Users",
				component: AllUsers
			},
			{
				path: "create_user",
				name: "Create Users",
				component: CreateUser
			},
			{
				path: "company_locations",
				name: "ComapanyLocations",
				component: CompanyLocations
			},
			{
				path: "add_location",
				name: "AddLocation",
				component: AddLocation
			},
			{
				path: "company_certificates",
				name: "CompanyCertificates",
				component: CompanyCertificates
			},
			{
				path: "company_courses",
				name: "CompanyCourses",
				component: CompanyCourses
			},
			{
				path: "location_details",
				name: "LocationDetails",
				component: LocationDetails
			},
			{
				path: "company_coursefolders",
				name: "CompanyCourseFolders",
				component: CompanyCourseFolders
			},
			{
				path: "course_catalog",
				name: "CourseCatalog",
				component: CourseCatalog
			},
			{
				path: "company_course_details",
				name: "CompanyCourseDetails",
				component: CompanyCourseDetails
			},
			{
				path: "course_instructions",
				name: "CourseInstructions",
				component: CourseInstructions
			},
			{
				path: "lesson_form",
				name: "LessonForm",
				component: LessonForm
			},
			{
				path: "edit_course",
				name: "EditCourse",
				component: EditCourse
			},
			{
				path: "employee_certificates",
				name: "EmployeeCertificates",
				component: EmployeeCertificates
			},
			{
				path: "employee_resources",
				name: "EmployeeResources",
				component: EmployeeResources
			},
			{
				path: "employee_documents",
				name: "EmployeeDocuments",
				component: EmployeeDocuments
			},
			{
				path: "employee_courses",
				name: "EmployeeCourses",
				component: EmployeeCourses
			},
			{
				path: "/add_employee",
				name: "AddEmployee",
				component: AddEmployee
			},
			{
				path: "/company_employees",
				name: "CompanyEmployees",
				component: CompanyEmployees
			},
			{
				path: "/resources",
				name: "Resources",
				component: Resources
			},
			{
				path: "/login_report",
				name: "LoginReport",
				component: LoginReport
			},
			{
				path: "/activity_report",
				name: "ActivityReport",
				component: ActivityReport
			},
			{
				path: "/course_fail_pass_report",
				name: "CourseFailPassReport",
				component: CourseFailPassReport
			},
			{
				path: "/onboarding_report",
				name: "OnboardingReport",
				component: UserOnboardingReport
			},
			{
				path: "/all_onboarding_report",
				name: "AllOnboardingReport",
				component: AllOnboardingReport
			},
			{
				path: "/hrform_report",
				name: "HrFromReport",
				component: HrFormReport
			},
			{
				path: "/create_subadmin",
				name: "CreateSubAdmin",
				component: CreateSubAdmin
			},
			{
				path: "/subadmin",
				name: "AllSubAdmin",
				component: AllSubAdmin
			},
			{
				path: '/scorm-report',
				name: 'ScormReport',
				component: ScormReport
			},
			{
				path: '/scorm-report/details/employee/:employee_id/course/:course_id',
				name: 'ScormReportDetails',
				component: ScormReportDetails
			},
		]
	},
	authPages
];

export default routes;
